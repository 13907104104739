<template>
  <en-drawer :model-value="modelValue" title="收货人信息" @close="$emit('update:model-value', false)">
    <en-form>
      <en-form-item label="收货人">
        <en-input :model-value="form.data.customer?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="手机号">
        <en-input :model-value="form.data.phoneNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="所在区域">
        <en-input :model-value="form.data.town?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="详细地址">
        <en-input :model-value="form.data.address" disabled></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="$emit('update:model-value', false)">取消</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallComboOrderDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.init()
          this.form.data.id = this.data?.id
          this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/order/:mallComboOrderId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        }
      }
    }
  }
})
</script>
